import React from 'react'
import '../styles/home.css'
import { FiPhone } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";


const home = () => {
    return (
        <div className='homemain'>
           
            <div className='homebox1'>
                <div className='homename'>
                    <h1>PANDI DURAI.S</h1>
                    <h3>Proprietor</h3>
                    <pre className='add'>
                    <div className='phno'><FiPhone /> +91-9944042138</div>
                    <div className='email'><MdMailOutline /> reachus@theautomationpeople.in,<br/>tn58technologies@gmail.com</div>
                    <div className='address'><IoLocationOutline /> No: 9A,Sekkadi Street,P.T.Rajan Road,<br/> &nbsp; Narimedu,Madurai-625002</div>
                </pre>
                </div>
            </div>
            <div className='homebox2'>
                <br/>
                <br/>
                <br/>
                <h1>website under development</h1>   
            </div>
            


        </div>
    )
}

export default home