
import './App.css';
import Nav from './menu/Nav'
import Home from './pages/home'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Product from './pages/Product';

function App() {
  return (
    <div className="App">
    
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Product" element={<Product />} />
      </Routes>
    </Router>


    </div>
  );
}
export default App;
