import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Burger from "./Burger"; // Ensure this component exists
import "./Nav.css";

export default function Nav() {
  const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerIsOpen(!hamburgerIsOpen);
  };

  return (
    <div id="nav">
      <div className="burger-list" onClick={toggleHamburger}>
        <Burger isOpen={hamburgerIsOpen} />
      </div>
      <div className="figure">
        the Automation People
        </div>
        <div className="header-title">
          the Automation People
        </div>
      <div className={`nav-list ${hamburgerIsOpen ? "nav-burger active" : ""}`}>
        
        <ul>
            <li>
              <NavLink to="/" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/Product" activeClassName="active">
                Product
              </NavLink>
            </li>
        </ul>
      </div>
    </div>
  );
}

