import React from 'react'

const Product = () => {
  return (
    <div className='Productmain'>
        <h1>Product</h1>
        <div className='prolist'>
            <ul>
                
                
            </ul>
        </div>
    </div>
  )
}

export default Product